<template>
	<el-container>
		<el-header>
			<div class="banner-wrap"
				:style="{'background-image': 'url(' + require('@/assets/img/banner.webp') + ')'}">
				<div class="text-wrap">
					<p>休斯顿好房网</p>
					<p>房东登录系统</p>
				</div>
			</div>
		</el-header>
		<el-main>
			<div class="login_container">
				<div class="login_box">
					<div class="avatar_box">
						<img src="@/assets/img/gigilogo.svg" alt="">
					</div>
					<el-form ref="loginFormRef" label-width="0px" class="login_form" :model="loginForm"
						:rules="loginFormRules">
						<el-form-item prop="username">
							<el-input prefix-icon="iconfont icon-user" v-model="loginForm.username"
								placeholder="输入登录邮箱"></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input prefix-icon="iconfont icon-3702mima" v-model="loginForm.password"
								type="password" placeholder="输入登录密码"></el-input>
						</el-form-item>
						<el-form-item class="btns">
							<el-button type="primary" @click="login">登录</el-button>
							<el-button type="info" @click="resetLoginForm">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-main>
		<el-footer class="login-footer">© {{$dayjs().$y}} GIGI. All Rights Reserved | Powered by <a
				href="https://wisharemedia.com">WiShare Media Group</a></el-footer>
	</el-container>
</template>

<script>
export default {
	name: 'Login',
	data() {
		return {
			loginForm: {
				username: '',
				password: ''
			},
			loginFormRules: {
				username: [
					{required: true, message: '请输入登录邮箱', trigger: 'blur'},
					{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']}
				],
				password: [
					{required: true, message: '请输入登录密码', trigger: 'blur'},
				]
			}
		};
	},
	methods: {
		resetLoginForm() {
			this.$refs.loginFormRef.resetFields();
		},
		login() {
			this.$refs.loginFormRef.validate(async valid => {
				if (!valid) return;
				const {data: res} = await this.$http.post('clientlogin', this.loginForm);
				if (res.meta.status !== 200) return this.$message.error('用户或密码错误');
				this.$message.success('登陆成功');
				window.sessionStorage.setItem('token', res.data.token);
				window.sessionStorage.setItem('username', res.data.username);
				window.sessionStorage.setItem('email', res.data.email);
				window.sessionStorage.setItem('cid', res.data.id);
				this.$router.push('/home');
			});
		}
	}
}
</script>

<style lang="less" scoped>
.el-container {
	position: relative;
	background-color: #333333;
	height: 100%;
	.el-header {
		padding: 0;
		height: 300px !important;
		.banner-wrap {
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			.text-wrap {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				color: #fff;
				font-style: normal;
				font-weight: bold;
				font-size: 40px;
				line-height: 48px;
				p:first-child {
					margin-bottom: 10px;
				}
			}
		}
	}
	.el-main {
		.login_container {
			.login_box {
				width: 450px;
				height: 300px;
				background-color: #fff;
				border-radius: 3px;
				position: relative;
				margin: 150px auto 80px;

				.avatar_box {
					height: 130px;
					width: 130px;
					border: 1px solid #eee;
					border-radius: 16px;
					padding: 10px;
					box-shadow: 0 0 10px #ddd;
					position: absolute;
					left: 50%;
					transform: translate(-50%, -50%);
					background-color: #fff;
					img {
						width: 100%;
						height: 100%;
						border-radius: 16px;
						background-color: #eee;
					}
				}
				.login_form {
					position: absolute;
					bottom: 0;
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					.btns {
						display: flex;
						justify-content: flex-end;
					}
				}
			}
		}
	}
	.el-footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: #333333;
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 60px;
		color: #f2f2f2;
		a {
			color: #f2f2f2;
		}
	}
}
</style>